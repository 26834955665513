import { useRef, useState, useMemo } from "react";
import {
  Text,
  Stack,
  Icon,
  Button,
  VStack,
  Box,
  HStack,
  useBreakpointValue,
  Heading,
  Link,
} from "@chakra-ui/react";
import { KYDEvent as IKYDEvent } from "@common/interfaces/KYDEvent";
import { KYDTicketType, KYDWaitlistOrder } from "@common/interfaces/KYDTicket";
import { useCollapse } from "@collapsed/react";

import TicketTypes from "./TicketTypes";

import { ModalType, markdownToLineBreaks } from "../utils";
import { FaCartPlus, FaBell } from "react-icons/fa";
import { Wallet } from "@common/interfaces/KYDUser";
import { KYDCart, KYDCartV2 } from "@common/interfaces/KYDCart";
import FloatingBottomPanel from "./FloatingBottomPanel";
import RemindMeOnSale from "./RemindMeOnSale";
import dayjs from "dayjs";
import InsertCode from "./InsertCode";
import { walletHasWaitlistBuyOrder } from "./WaitList/utils";
import WaitlistTicketTypes from "./WaitList/WaitlistTicketTypes";
import Markdown from "react-markdown";
import { ChevronDownIcon } from "@chakra-ui/icons";
import RelatedShows from "./RelatedShows";

function KYDEventDetail({
  kydEvent,
  draftCart,
  cart,
  wallet,
  onAddToCart,
  onRemindMe,
  onUpdateTicketTypeQuantity,
  modalType,
  loading,
  onRemindMeWhenOnSale,
  onGoToTicketCode,
  code,
  onJoinWaitlist,
}: {
  kydEvent: IKYDEvent;
  wallet: Wallet | null;
  draftCart?: { id: string; total: number; ticket_types?: object };
  loading: Record<string, boolean>;
  onRemindMeWhenOnSale: (kydEventId: string) => void;
  cart: KYDCart | KYDCartV2 | null;
  onAddToCart: () => void;
  onRemindMe: () => void;
  onUpdateTicketTypeQuantity: (
    ticket_type: KYDTicketType,
    action: string
  ) => void;
  code?: string;
  modalType: ModalType;
  onGoToTicketCode: (code: string) => void;
  onJoinWaitlist: (ticketType: KYDTicketType) => void;
}) {
  const showReminder =
    kydEvent.display_options.show_on_sale_reminder &&
    kydEvent.ticket_types.length === 0;

  const insertCodeRef = useRef<HTMLInputElement | null>();
  const [internalCode, setCode] = useState(code);
  const [codeDisplayMode, setCodeDisplayMode] = useState<"closed" | "open">(
    (internalCode && internalCode.length > 0) || showReminder
      ? "open"
      : "closed"
  );
  const params = new URLSearchParams(window.location.search);
  const utm_source = params.get("utm_source");

  const collapsedHeight = useBreakpointValue({
    base: kydEvent?.description && kydEvent?.description.length > 50 ? 75 : 60,
    md: 100,
  });
  const { getCollapseProps, setExpanded, isExpanded } = useCollapse({
    defaultExpanded: false,
    collapsedHeight: collapsedHeight,
  });

  const descriptionWithLineBreaks = useMemo(() => {
    return markdownToLineBreaks(kydEvent?.description);
  }, [kydEvent]);

  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const isBuyButtonFloating = draftCart.total > 0;

  return (
    <VStack w="100%" spacing={{ base: 0, md: 4 }}>
      <Stack
        w="100%"
        spacing={{ base: 0, md: 4 }}
        bg={{ base: "none", md: "white" }}
        px={[2, null, 5]}
        pt={[0, null, 4]}
        pb={4}
        rounded={{ base: "none", md: "lg" }}
        roundedBottom={{
          base: "none",
          md: kydEvent?.related_shows.length ? "lg" : "none",
        }}
      >
        <Stack spacing={0}>
          <HStack width={"full"} justifyContent={"space-between"} spacing={0}>
            <Stack spacing={0}>
              <Text
                display={{ base: "none", md: "table-cell" }}
                w="100%"
                textAlign={"left"}
                fontWeight={"bold"}
                fontSize="xl"
              >
                {kydEvent.display_mode === "rsvp"
                  ? "Register"
                  : "Get Your Tickets"}
              </Text>
              {kydEvent.display_mode === "normal" &&
              kydEvent.display_options?.price_mode === "inclusive" ? (
                <Text
                  display={{ base: "none", md: "flex" }}
                  fontSize={"sm"}
                  textAlign={"left"}
                  fontWeight={"bold"}
                >
                  All-In Pricing. No surprises later.
                </Text>
              ) : null}
              {kydEvent.display_mode === "rsvp" &&
              (!kydEvent.display_options ||
                !kydEvent.display_options.hide_event_subheader) ? (
                <Text
                  color={{ base: "white", md: "gray.700" }}
                  maxW={"500px"}
                  fontSize={"sm"}
                >
                  RSVP with your email and get notified when tickets go on sale
                  for the next event, and get direct updates from{" "}
                  {kydEvent.organization
                    ? kydEvent.organization.name
                    : " this host"}
                  .
                </Text>
              ) : null}
            </Stack>
            {isMobile ? null : (
              <InsertCode
                loading={loading.code}
                focus={!!code}
                ref={insertCodeRef}
                setCode={setCode}
                setCodeDisplayMode={setCodeDisplayMode}
                internalCode={internalCode}
                onGoToTicketCode={onGoToTicketCode}
                codeDisplayMode={codeDisplayMode}
              />
            )}
          </HStack>
        </Stack>
        <RemindMeOnSale
          isLoading={loading.reminder}
          onSaleDate={dayjs(
            kydEvent.on_sale_at_override || kydEvent.on_sale_at
          ).tz(kydEvent.timezone)}
          onRemindMe={() => onRemindMeWhenOnSale(kydEvent.id)}
          show={showReminder}
          hasReminder={wallet?.has_reminder}
        />
        {kydEvent.ticket_types.length === 0 && !showReminder ? (
          <VStack color={{ base: "white", md: "black" }} w="100%" py={4}>
            <Text>Please enter a presale code to view available tickets</Text>

            {isMobile ? null : (
              <Button
                bg={{ base: "white", md: "black" }}
                color={{ base: "black", md: "white" }}
                _hover={{ bg: { base: "gray.100", md: "gray.700" } }}
                onClick={() => {
                  setCodeDisplayMode("open");
                  if (insertCodeRef.current) {
                    insertCodeRef.current.focus();
                  }
                }}
              >
                Enter code
              </Button>
            )}
          </VStack>
        ) : null}
        {isMobile ? (
          <Stack spacing={4} w="100%" pt={showReminder ? 4 : 0}>
            {showReminder ? (
              <Text fontWeight={"bold"} textAlign={"center"} color="white">
                OR
              </Text>
            ) : null}
            <InsertCode
              loading={loading.code}
              focus={!!code}
              setCode={setCode}
              setCodeDisplayMode={setCodeDisplayMode}
              internalCode={internalCode}
              onGoToTicketCode={onGoToTicketCode}
              codeDisplayMode={codeDisplayMode}
            />
          </Stack>
        ) : null}
        {kydEvent.ticket_types.length > 0 ? (
          <VStack w="100%" spacing={1}>
            <TicketTypes
              wallet={wallet}
              kydEvent={kydEvent}
              cart={cart}
              quantities={draftCart.ticket_types || {}}
              ticketTypes={kydEvent.ticket_types}
              updateQuantity={onUpdateTicketTypeQuantity}
              onJoinWaitlist={onJoinWaitlist}
            />
            <FloatingBottomPanel isFloating={isBuyButtonFloating}>
              <Button
                width={"100%"}
                bg={{ base: "white", md: "black" }}
                color={{ base: "black", md: "white" }}
                _hover={{ bg: { base: "gray.100", md: "gray.700" } }}
                isLoading={modalType === ModalType.SECURING_TICKETS}
                isDisabled={draftCart.total === 0}
                onClick={onAddToCart}
                h="50px"
                rightIcon={
                  kydEvent.display_mode === "normal" ? (
                    <Icon
                      color={{ base: "black", md: "white" }}
                      as={FaCartPlus}
                    />
                  ) : undefined
                }
              >
                {kydEvent.display_mode === "rsvp"
                  ? "RSVP Now"
                  : `Get${
                      draftCart.total > 0 ? ` ${draftCart.total}` : ""
                    } Ticket${
                      draftCart.total > 1 || !draftCart.total ? "s" : ""
                    }`}
              </Button>
            </FloatingBottomPanel>
          </VStack>
        ) : null}

        <Stack display={{ base: "block", md: "none" }} pt={4} textColor="white">
          <Stack pt={2} borderTop={"1px"} borderColor="gray.700">
            <Text fontWeight="bold" fontSize="xl">
              Event Info
            </Text>
            <Stack
              spacing={1}
              pos="relative"
              onClick={() => setExpanded(!isExpanded)}
              {...getCollapseProps()}
            >
              {!isExpanded ? (
                <VStack
                  rounded={"xl"}
                  spacing={0}
                  justifyContent="flex-end"
                  pointerEvents={"none"}
                  h="30px"
                  pos="absolute"
                  width="100%"
                  bottom={0}
                  background={
                    !isExpanded
                      ? `linear-gradient(to bottom, transparent, ${
                          isMobile ? "rgba(0,0,0,0.60)" : "white"
                        })`
                      : null
                  }
                >
                  <ChevronDownIcon fontSize="2xl" />
                </VStack>
              ) : null}
              {descriptionWithLineBreaks ? (
                <Markdown
                  className="mdx-editor"
                  components={{
                    a: (props) => (
                      <Link
                        href={props.href}
                        target={"_blank"}
                        textDecoration={"underline"}
                        color={isMobile ? "blue.200" : "blue.800"}
                      >
                        {props.children}
                      </Link>
                    ),
                    h1: (props) => <Heading {...props} fontSize={24} />,
                    h2: (props) => <Heading {...props} fontSize={22} />,
                    h3: (props) => <Heading {...props} fontSize={20} />,
                    h4: (props) => <Heading {...props} fontSize={18} />,
                    h5: (props) => <Heading {...props} fontSize={16} />,
                    p: Text,
                  }}
                >
                  {descriptionWithLineBreaks}
                </Markdown>
              ) : null}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      {kydEvent?.related_shows.length ? (
        <Stack
          bg={{ base: "none", md: "white" }}
          w="full"
          roundedTop={{
            base: "none",
            md: "lg",
          }}
          pt={[0, null, 4]}
          pb={4}
        >
          <RelatedShows relatedShows={kydEvent?.related_shows} />
        </Stack>
      ) : null}
    </VStack>
  );
}

export default KYDEventDetail;
